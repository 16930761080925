import React from "react";
import ReactDOM from "react-dom";

import MuxPlayerWrapper from "@components/mux-player-wrapper";

const videos = document.querySelectorAll("[data-behavior~=mux-player]");

if (videos.length > 0) {
  videos.forEach((video) => {
    const slug = video.dataset.slug;
    const { env } = window;
    const params = env[slug];

    ReactDOM.render(<MuxPlayerWrapper params={params} />, video);
  });
}
