import MuxPlayer from "@mux/mux-player-react";
import React, { useState } from "react";

export default function MuxPlayerWrapper(props) {
  const { params } = props;
  const { aspectRatio, paddingBottom, metadata, playbackId, poster, title, tokens } = params;
  const [hasVideo, setHasVideo] = useState(false);

  function handleClick() {
    setHasVideo(true);
  }

  const containerStyle = { paddingBottom: `${paddingBottom}%`, width: "100%" };
  const coverStyle = { aspectRatio };
  const playerStyle = { aspectRatio, "--center-controls": "none" };

  return (
    <div className="Video-asset" style={containerStyle}>
      <div className="Video-assetContainer">
        {!hasVideo && <img className="Video-assetImage" src={poster} style={coverStyle} />}

        {hasVideo && (
          <MuxPlayer
            autoplay={true}
            className="Video-assetVideo"
            customDomain="vidsrv.net"
            defaultHiddenCaptions={true}
            metadata={metadata}
            playbackId={playbackId}
            poster={poster}
            preload="none"
            streamType="on-demand"
            style={playerStyle}
            title={title}
            tokens={tokens}
          />
        )}

        {!hasVideo && (
          <>
            <div class="Video-playOverlay" onClick={handleClick} />
            <div class="Video-playButton" />
          </>
        )}
      </div>
    </div>
  );
}
